
$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;



.Titleist-body {
    // background-color: rgb(212, 71, 71) ;
    width: 100%;
    height:100;
    // color: white;
    overflow-x: hidden;
    

}


.titleist-content {
    margin-top: 10%;
    display: flex;
    // padding-top: 10rem;
    flex-direction: column;
    justify-content: center;
    color: rgba(0,0,0,0.52);
  

    h3.t-title {
        // margin-top: 5rem;
        text-transform:uppercase;
        letter-spacing:2px;
        align-self: center;
        margin-bottom: 2rem;
        font-size:24px;	
    }


    p {
        align-self: center;
        line-height: 1.4rem;
        text-align: center;
        font-size: .9rem;
        margin-bottom: 5rem;
        width: 70%;

        @media screen and (min-width: $break-medium) {
            font-size: 12px;
            // color: #00000085;

        }


    }

    .title-img-container {
        width: 40%;
        min-width: 300px;
        position: relative;
        margin: 0 auto;

        img {
            margin-bottom: 5rem;
            // border: 5px solid black;
            width: 100%;
            height: auto;
            // filter: drop-shadow(0 0 5px #333); 
        }
    }

}

.thumbnail {
   opacity: 0;
//    transform: translateY(50px);
   transition: all 1s ease-out;
}

.thumbnail.fade-in {
    opacity: 1;
    // transform: translateY(0);
}

