$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;




.good-body {
    // background-color: rgb(204, 177, 124);
    width: 100%;
    overflow-x: hidden;
    height:100;
    // color: white;
}

.goodship-content {
    margin-top: 10%;
   align-items: center;
   display: flex;
   color: rgba(0,0,0,0.52);
   flex-direction: column;


    h1{
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 2rem;

    }

    p {
        font-size: 12px;
        line-height: 30px;
  
    }
}


.print, .tea-party {
    text-align: center;
    

    h2 {
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-top: 4rem;
        margin-bottom: 3rem;
    }
    img {
        width: 100%;
        height: auto;
        margin-bottom: 4rem;
    }

    
    
}

.good-pre-roll{
    width: 100%;
    height: 400px;

   
}

.good-insta{
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 60px;
    .insta-wrap{
        padding-left: 17px;
        padding-right: 17px;
        display: flex;

        .insta-img{
            margin: 2%;
            img {
                max-width: 100%;
                height: auto;
                min-width: 100px;
                // box-shadow: 0 4px 33px rgba(0,0,0,.22),0 0 0 1px rgba(0,0,0,.04);
            }

        }

        
    }
}

.print{
    margin-top: 5rem;
    width: 40%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-self: center;
    
}


.tea-party {
    max-width: 950px;
}
.only-tea{
    margin-top: 200px;
}