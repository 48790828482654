$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;




.shampoo-body {
    // background-color: rgb(172, 171, 169);
    width: 100%;
    overflow-x: hidden;
    height:100;
    // color: white;
}

.shampoo-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    color: rgba(0,0,0,0.52); 


    h1{
        margin-top: 5rem;
        font-size: 24px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 2rem;

    }

    p {
        font-size: 12px;
        line-height: 1.5rem;     
    }
}


.print, .tea-party {
    text-align: center;
    

    h2 {
        font-size: 2rem;
        margin-top: 4rem;
        margin-bottom: 3rem;
    }
    img {
        width: 100%;
        height: auto;
        // padding-bottom: 4rem;
    }
    
}

.print{
    margin-top: 5rem;
    width: 40%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-self: center;
    
}

.pre-roll-shampoo {
    margin-bottom: 8rem;
    align-items: center;
    flex-direction: column;
    display: flex;
   
    width: 100%;
  

    iframe{
        width: 100%;

    }
    
}
.tea-party {
    max-width: 950px;
}

.jumbo-tron{
    padding-top: 9rem;
    padding-bottom: 5rem;
    width: 60%;
    max-width: 950px;
    img {
        width: 100%;
        height: auto;
    }

}


.selfie-gif{
    text-align: center;
    img{
        width: 50%;
    }
}