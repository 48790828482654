$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;




.cocktail-body {
    // background-color: rgb(204, 177, 124);
    width: 100%;
    overflow-x: hidden;
    height:100;
    color: rgba(0,0,0,0.52);

    p{
        font-size: 12px;
    }
}

.cocktail-content {
    padding-top: 10rem;
    align-items: center;
    display: flex;
    flex-direction: column;


    h1{
        margin-top: 5rem;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 2rem;

    }

    p {
        line-height: 1.5rem;
        margin-bottom: 50px;
    }
}


 .cocktail-promo, .cocktail-store {
    text-align: center;
    max-width: 750px;
    p {
        margin-bottom: 5rem;
    }
    

    h2 {
        text-transform: uppercase;
        letter-spacing: 2px;

        font-size: 2rem;
        margin-top: 4rem;
        margin-bottom: 3rem;
    }
    img {
        width: 100%;
        height: auto;
        padding-bottom: 4rem;
    }
    
}
.cocktail-insta{
    max-width: 1200px;
    margin: 0 auto;
    width: 84%;
    margin-bottom: 60px;
    .insta-wrapper{
        padding-left: 17px;
        padding-right: 17px;
        display: flex;

        .insta-img{
            margin: 3%;
            img {
                max-width: 100%;
                height: auto;
                min-width: 100px;
               
                // box-shadow: 0 4px 33px rgba(0,0,0,.22),0 0 0 1px rgba(0,0,0,.04);
                // box-shadow: 0 4px 33px rgba(0,0,0,.22),0 0 0 1px rgba(0,0,0,.04);
                // box-shadow: inset 0 0 0 1px rgba(128,128,128, 0);
                // box-shadow: .25em 0 0 rgba(255,255,255,0),-.25em 0 0 rgba(255,255,255,0)
            
            }
        }
    }
    
}


.cocktail-promo {
    
   
}

.cocktail-store {
    
}