$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;




.french-body {
    // background-color: rgb(247, 209, 97);
    width: 100%;
    height:100;
    // color: white;
    overflow-x: hidden;


    h2, p {
     text-align: center;

    }

    .french-header {
        margin-top: 5rem;
        margin-bottom: 2rem;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    p {
        font-size: 12px;
        margin-bottom: 5rem;
    }
  
}

.french-content{
    padding-top: 10rem;
    color: rgba(0,0,0,0.52); 
}



.french-img-container {
    width: 40%;
    min-width: 250px;
    position: relative;
    margin: 0 auto;

    @media screen and (min-width: $break-medium) {
        width: 35%; 
    }

    img {
        margin-bottom: 4rem;
        border: 1px solid black ;
        height: auto;
        max-width: 100%;
    }
}