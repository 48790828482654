$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;




.imean-body {
    // background-color: rgb(204, 200, 189);
    width: 100%;
    height:100;
    // color: white;
    overflow-x: hidden;

    h2, p {
     text-align: center;

    }
  
}

.imean-content {
    margin-top: 10%;
    color: rgba(0,0,0,0.52); 

    p{
        font-size: 12px;
        line-height: 25px;
    }
}
.imean-header {
    margin-bottom: 2rem;
    font-size: 24px;
    letter-spacing: 2px;
}



.imean-img-container {
    width: 40%;
    min-width: 250px;
    position: relative;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @media screen and (min-width: $break-medium) {
        width: 60%; 
    }

    video {
        margin-top: 5rem;
        margin-bottom: 4rem;
        border: 1px solid black ;
        height: auto;
        max-width: 100%;
    }
}