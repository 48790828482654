$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;



.now-body {
    // background-color: #b3e4e4 ;
    width: 100%;
    overflow-x: hidden;
    height: 100%;
}


.now-img-container {
    width: 40%;
    min-width: 250px;
    position: relative;
    margin: 0 auto;

    img {
       
        height: auto;
        max-width: 100%;
        margin-bottom: 4rem;
    }
}

.now-content{
    margin-top: 10%;
    color: rgba(0,0,0,0.52);
    display: flex;
    flex-flow: column;
    


    .now-title {
        margin-bottom: 5rem;
    
        h2 {
            text-align: center;
            margin-bottom: 2rem;
            font-size: 24px;
            letter-spacing: 2px;
            text-transform: uppercase;
        
        }

        p {
            font-size: 12px;
            line-height: 25px;
            text-align: center;
        
        
        }
    }
    .brandless-insta-section{
        max-width: 1200px;
        margin: 0 auto;
        width: 84%;
        margin-bottom: 60px;

        .insta-wrapper{
            padding-left: 17px;
            padding-right: 17px;
            display: flex;
            
            .insta-img{
                margin: 3%;
                img {
                    max-width: 100%;
                    height: auto;
                    min-width: 100px;
                   
                    box-shadow: 0 4px 33px rgba(0,0,0,.22),0 0 0 1px rgba(0,0,0,.04);
                    // box-shadow: 0 4px 33px rgba(0,0,0,.22),0 0 0 1px rgba(0,0,0,.04);
                    // box-shadow: inset 0 0 0 1px rgba(128,128,128, 0);
                    // box-shadow: .25em 0 0 rgba(255,255,255,0),-.25em 0 0 rgba(255,255,255,0)
                
                }
            }

            

        }

        

    }

}
