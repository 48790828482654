
$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;

.yoga-body {
    width: 100%;
	height:100;
	overflow-x: hidden;
}

.collab-artists {
	text-align: center;
	font-size: 12px;
	line-height: 1.5rem;
	margin-bottom: 3rem;
	font-style: italic;

	p{
		a{
			text-decoration: none;
			color: rgba(0,0,0,0.52);

			:hover{
				cursor: pointer;
			}
		}
	}
}


.cham-yoga {
	display: block;
	
}

	
.yoga-content {
	margin-top: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: rgba(0,0,0,0.52);

	
	.yoga-title {
		width: 70%;
		margin-bottom: 5rem;
		text-align: center;
		transition: all .4s ease-in-out;
		

		h2 {
			display: flex;
			justify-content: center;
			font-size: 24px;
			text-transform: uppercase;
			letter-spacing: 2px;
			margin-bottom: 2rem;
			color: rgba(0,0,0,0.52);

			// &:hover {
			// 	color: rgb(8, 8, 10);
			// }
		}

		p {
			line-height: 25px;
			font-size: 12px;
			align-self: center;
			text-align: justify;

			@media screen and (min-width: $break-medium) {
				line-height: 30px;
				text-align: center;
			}
		
		}

	}

	.yoga-img-first, .yoga-img-second, .yoga-img-third {
	   width: 40%;
	

	   min-width: 300px;
        
        img {
			transition: all .4s ease-in-out;
            margin-bottom: 5rem;
            width: 100%;
			height: auto;
			box-shadow: 0 10px 6px -6px #777;
		}
	
	}
	.yoga-img-third{
		margin-bottom: 2rem;
	}

	.yoga-digital{
		width: 50%;
	}

}
