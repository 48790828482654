/* 
==============================================
CONTENT:	James McAlister's Portfolio
AUTHOR:		Kelsey Zahand
==============================================

TABLE OF CONTENTS:

1. Main Layout
2. Content

==============================================
*/

@import 'shampoo';
@import 'cocktail';
@import 'imean';
@import 'duracell';
@import 'french';
@import 'now';
@import 'title';
@import 'yoga';
@import 'good';
@import 'about';
@import 'reset';

@font-face {
	font-family: 'Din';
	src: url("../font/din-m.ttf");
}

$break-small: 500px;
$break-medium: 650px;
$break-md-l: 850px;
$break-large: 1200px;




/* 1. Main Layout ================================= */

* {
	box-sizing: border-box;
}

body {
	width: 100%;
	height: 100%;
	overflow: scroll;
	background-color: #fcfcfc;
	font-family: 'Din', sans-serif;

	@media screen and (min-width: $break-medium) {
		
	}

}


.content {
	position: relative;
	margin: 30px auto;
	display: flex;
	justify-content: center;
	max-width: 500px;
	flex-wrap: wrap;

	@media screen and (min-width: $break-medium) {
		justify-content: unset;
		max-width: 700px;
	}		
}

.example {
	display: inline;
	position: relative;
	overflow: hidden;

	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		right: 0;
		width: 0;
		bottom: -5px;
		background: rgb(157, 228, 157);
		height: 3px;
		transition-property: width;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}

	&:hover:after,
	&:focus::after {
		left: 0;
		right: auto;
		width: 100%;
	}

}




/* 2. Content ================================= */




header {
	// position: fixed; 
	display: flex;
	width: 100%;
	z-index: 10;
	// justify-content: space-around;
	padding-top: 3rem;
	// margin-bottom: 3rem;
	background-color: #fcfcfc;
	flex-direction: column;
	align-items: center;
	padding-bottom: 20px;

	@media screen and (min-width: $break-medium) {
		padding: 4rem;
		justify-content: space-around;
		margin-top: 0rem;
		margin-bottom: 0;
	}

		.name-copy {
			cursor: pointer;
			display: flex;
			// align-items: flex-end;

			margin-bottom: 32px;
			flex-direction: column;
			// background-color: red;
			

			@media screen and (min-width: $break-medium) {
				width: 50%;
				// align-items: flex-end;
			}
			
			.logo-word-wrap{
				display: flex;
				flex-direction: column;
				padding-left: 6px;

				align-items: center;
				@media screen and (min-width: $break-medium) {
					
				}

			}
			#logo-cham {
				height: 70px;
				margin-bottom: 21px;
				@media screen and (min-width: $break-medium) {
					
				}
			}

			h1 {
				font-size: 1.5rem;
				margin-bottom: .5rem;
			}
			h2 {
				font-size: .8rem;
				display: flex;
				// color: rgba(233, 233, 233, 0.747);
				text-transform: uppercase;
				@media screen and (min-width: $break-medium) {
					// justify-content: flex-start;
				}
			}

		}


	.nav-not-hamburger{
		width: 50%;
		// align-items: center;
		
		// display: flex;
		// margin-bottom: 17px;
		// justify-content: flex-end;

		@media screen and (min-width: $break-medium) {
			transition: all .4s ease-in-out;
			justify-content: center;
        }

		ul {
			display: flex;
			justify-content: space-evenly;
			
			li {
				// padding-left: 2rem;
				
				a {
					font-size: 1.2rem;	
					color: black;
					text-decoration: none;	
				}
			
			}
		}
}



}

	.lightbox {
		display: none;
		position: fixed;
		z-index: 999;
		width: 100%;
		height: 100%;
		text-align: center;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.8);
		overflow: scroll;
	}
	
	.lightbox img {
		max-width: 90%;
		margin-top: 2%;
	}
	
	.lightbox:target {
		outline: none;
		display: block;
	}



.home-content {
	// padding: 2rem;
	// padding-top: 250px;
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;


	.home-mobile-only{
		transition: all .4s ease-in-out;
		width: 100%;
		margin-top: 10%;
		@media screen and (min-width: $break-medium) {
			display: none;
		}
		
		
				a div{
					cursor: pointer;
					background-repeat: no-repeat;
					background-position: center;
					height: 200px;
				}
				
				a:nth-child(1) div{
					background-image: url('../img/imean-logo.png');
					background-size: 95%;
					background-color: #F8F5E7;
				}
				
				a:nth-child(2) div{
					background-size: 50%;
					background-image: url('../img/now-n-later-logo.png');
					background-color: #CCC1BD;
				}
				a:nth-child(3) div{
					background-image: url('../img/Titleist_logo.svg.png');
					background-size: 50%;
					background-color: #e96363;
				}
				a:nth-child(4) div{
					background-image: url('../img/goodship-logo.png');
					background-size: 30%;
					background-color: #70C4C6;;
				}
				a:nth-child(5) div{
					background-image: url('../img/duracell_logo_.png');
					background-size: 50%;
					background-color: #B3CAAA;
				}
				a:nth-child(6) div{
					background-image: url('../img/batiste-logo2.png');
					background-size: 50%;
					background-color: #f7977d;
				}
				a:nth-child(7) div{
					background-image: url('../img/cock-logo.png');
					background-size: 27%;
					background-color:#E8B4B8;
				}
				a:nth-child(8) div{
					background-image: url('../img/frenchs-logo.png');
					background-size: 50%;
					background-color: #FCE27D;
				}
				a:nth-child(9) div{
					background-color: #BBE0F5;
					background-image: url('../img/yoga-logo-bg.png');
					background-size: 50%;
				
				}
				
				

		
				
				
		
		

	}

	// .home-mobile-only {
	// 	width: 100%;
		
	// 	ul{
	// 		li{
	// 			background: #48b1bf;
	// 			padding: 2rem;
	// 			text-align: center;
	// 			cursor: pointer;
	// 			a{
					
	// 				color: white;
	// 				padding: 100%;
	// 				padding-left: 1rem;
	// 				padding-top: 2rem;
	// 				padding-bottom: 2rem;
	// 				padding-right: 1rem;
	// 				text-transform: uppercase;
	// 				text-decoration: none;
				

	// 			}

	// 			&:hover {
	// 				background: #6dbc74;
	// 			}
	// 		}
	// 	}

	// 	@media screen and (min-width: $break-medium) {
	// 		display: none;
	// 	}
	// }


}



.big-box-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: -.4rem;
	
	display: none;
	
	@media screen and (min-width: $break-medium) {
		display: flex;
	}
	@media screen and (min-width: $break-md-l) {
		max-width: 700px;
		// margin-left: 0;
	
	}

	

}


$boxwidth: 200px;
$boxheight: 200px;


// h1, h2, h3, p {
// 	margin: 0 0 1em;
// }

.box-rotate-wrap {
	// float: left;
	// margin: 0 30px 30px 0;
	perspective: 1000px;
	display: block;

}
.box, .box > div {
	width: $boxwidth;
	height: $boxheight;
	margin: .2rem;
		
}

.box-rotate {
	position: relative;
	transition: all 0.8s ease;
	transform-style: preserve-3d;
	transform: translate3d(0, 0, -$boxheight/2);
}
.box-rotate > div {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	background: #fff;
	padding: 20px;
  	overflow: auto;
}

.box-rotate img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.box-rotate .front {
	background: white;
	z-index: 2;
	transform: rotate3d(0, 1, 0, 0deg) translate3d(0, 0, $boxwidth/2);
}
.box-rotate .back {
	background: orange;
	transform: rotate3d(0, 1, 0, 180deg) rotate(180deg) translate3d(0, 0, $boxwidth/2);
}
.box-rotate .top {
	height: $boxwidth;
	transform: rotate3d(1, 0, 0, 90deg) translate3d(0, 0, $boxwidth/2);
}
.box-rotate .bottom {
	background: #A46E9B;
	height: $boxwidth;
	transform: rotate3d(1, 0, 0, -90deg) translate3d(0, 0, $boxheight - $boxwidth/2);
}
.box-rotate .left {
	transform: rotate3d(0, 1, 0, -90deg) translate3d(0, 0, $boxwidth/2);
}
.box-rotate .right {
	
	transform: rotate3d(0, 1, 0, 90deg) translate3d(0, 0, $boxwidth/2);
}

.box-rotate .golf-left{
	background: rgb(233, 99, 99);
}
.box-rotate .yoga-top{
	background: #BBE0F5;
}
.box-rotate .good-right{
	background: #70C4C6;
}
.box-rotate .duracell-right{
	background: #B3CAAA;
}
.box-rotate .cocktail-left{
	background: #E8B4B8;
}
.box-rotate .shampoo-top{
	background: rgb(247, 151, 125);
}
.box-rotate .brandless-bottom{
	background: #CCC1BD;
}
.box-rotate .imean-bottom{
	background: #F8F5E7;
}



:hover > .box-rotate-top {
	transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 0, 0, -90deg);
}
:hover > .box-rotate-bottom {
	transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 0, 0, 90deg);
}
:hover > .box-rotate-left {
	transform: translate3d(0, 0, -$boxwidth/2) rotate3d(0, 1, 0, 90deg);
}
:hover > .box-rotate-right {
	transform: translate3d(0, 0, -$boxwidth/2) rotate3d(0, 1, 0, -90deg);
}
:hover > .box-rotate-back {
	transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 0, 0, -180deg);
}
.box-rotate-gyro .back {
	width: $boxheight;
	height: $boxwidth;
	transform: rotate3d(0, 1, 0, 180deg) rotate(90deg) translate3d($boxheight/2 - $boxwidth/2, -$boxheight/2 + $boxwidth/2, $boxwidth/2);
}
:hover > .box-rotate-gyro {
	transform: translate3d(0, 0, -$boxwidth/2) rotate3d(1, 1, 0, -180deg);
}

.box.box-blend div {
	background: #FCE27D;
	
}


#Titleist {
	background-image: url('../img/1-box-tablet.png');
	background-size: cover;
	background-repeat: no-repeat;

	@media screen and (min-width: $break-medium) {
		background-image: url('../img/3-cham.png');
	}		
}

#go-to-titleist {
	cursor: pointer;
	background-image: url('../img/Titleist_logo.svg.png');
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;	
}

#yoga {
	background-image: url('../img/9-cham.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $break-medium) {
		background-image: url('../img/9-cham.png');
		background-size: contain;
	}
}

#go-to-yoga {
	cursor: pointer;
	background-image: url('../img/yoga-logo-bg.png');
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
}

#goodship {
	background-image: url('../img/4-cham.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $break-medium) {
		background-image: url('../img/4-cham.png');
		background-size: contain;
	}
}

#go-to-goodship {
	cursor: pointer;
	background-image: url('../img/goodship-logo.png');
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center;
}

#Duracell {
	background-image: url('../img/5-cham.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	@media screen and (min-width: $break-medium) {
		background-image: url('../img/5-cham.png');	
	}
	
}

#go-to-duracell {
	cursor: pointer;
	background-image: url('../img/duracell_logo_.png');
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
}


#now-n-later{
	background-image: url('../img/5-box-tablet.png');
	background-repeat: no-repeat;
	background-position: center;
	@media screen and (min-width: $break-medium) {
		background-image: url('../img/2-cham.png');
		
	}
}

#go-to-now {
	cursor: pointer;
	background-image: url('../img/now-n-later-logo.png');
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
}

#French{
	background-image: url('../img/8-cham.png');
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $break-medium) {
		background-image: url('../img/8-cham.png');
	
	}
}

#go-to-french {
	cursor: pointer;
	background-image: url('../img/frenchs-logo.png');
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
}

#shampoo{
	background-image: url('../img/6-cham.png');
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $break-medium) {
		background-image: url('../img/6-cham.png');
	
	}
}

#go-to-shampoo {
	cursor: pointer;
	background-image: url('../img/batiste-logo2.png');
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center;
}

#cocktail{
	background-image: url('../img/7-cham.png');
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $break-medium) {
		background-image: url('../img/7-cham.png');
	
	}
}

#go-to-cocktail {
	cursor: pointer;
	background-image: url('../img/cock-logo.png');
	background-size: 40%;
	background-repeat: no-repeat;
	background-position: center;
}

#imean{
	background-image: url('../img/1-cham.png');
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $break-medium) {
		background-image: url('../img/1-cham.png');
	
	}
}

#go-to-imean {
	cursor: pointer;
	background-image: url('../img/imean-logo.png');
	background-size: 95%;
	background-repeat: no-repeat;
	background-position: center;
}



