
$break-small: 320px;
$break-medium: 720px;
$break-large: 1200px;


.duracell-body {
    // background-color: #B3CAAA ;
    width: 100%;
    height:100;
    overflow-x: hidden; 
    
}


.duracell-img-container {
    width: 40%;
    min-width: 250px;
    position: relative;
    margin: 0 auto;

    img {
        margin-bottom: 4rem;
        // border: 1px solid black ;
        height: auto;
        max-width: 100%;
    }
}

.duracell-content {
    display: flex;
    flex-flow: column;
   margin-top: 10%;
    color: rgba(0,0,0,0.52);

    .duracell-title {
    // margin-top: 5rem;
    align-self: center;
    text-align: center;

           h2 {
               font-size: 24px;
               text-transform: uppercase;
               letter-spacing: 2px;
               padding-bottom: 2rem;
           }
       
           p {
               font-size: 12px;
               margin-bottom: 5rem;
           }
       }

       .battery-line{
           border-bottom:1px solid grey;
           width: 100%;
            
       }

       .collab-battery {
           margin-top: 5rem;
       }

}

