
$break-small: 320px;
$break-medium: 736px;
$break-large: 1024px;


.about-body {
    

    
}

// .about-svg-container {
//     #about-cham {
//         transition: all .3s ease-in-out;
//         margin-top: -200px;
//         margin-left: 200px;
//         height: 200px;
         

//         @media screen and (min-width: $break-medium) {
//             margin-top: -300px;
//             margin-left: 500px;
//             height: 300px;
//         }

//         @media screen and (min-width: $break-large) {
//             margin-left: 700px;
//             width: 40%;
     
//         }
//     }
// }





.about-content {
    height: 100%;
	font-family: sans-serif;
    width: 100%;
 
    color: rgba(0,0,0,0.52);
    // background-color: blue;

    
    
    .about-wrap {
        transition: all .2s ease-in-out;
        flex-direction: column;
        display: flex;
        position: relative;
        margin-top: 100px;
        @media screen and (min-width: $break-medium) {
            flex-direction: row;
            justify-content: center;
           
        }
        .the-img {
            width: 100%;
            height: 250px;
            margin-bottom: 1em;
            background-repeat: no-repeat;
            background-image: url('../img/james-self.png');
            background-size: cover;
            background-position: 100% 40%;

            @media screen and (min-width: $break-medium) {
                width: 400px;
                height: 550px; 
                // position: absolute;
                // background-position: 100% 99%;  
            }
           
        }
        
       
        //
        

    
        .about-info {
            padding: 10px;
            z-index: 1;
            width: 100%;
            font-family: 'Din', sans-serif;
            color: rgba(0,0,0,0.52);
            // background-color: pink;

            @media screen and (min-width: $break-medium) {
                width: 40%;
                padding-left: 6%;
                max-width: 400px;
                // position: absolute;
                // right: 15%;
            }

            h2 {
                font-weight: 800;
                font-size: 24px;
                margin-bottom: 1rem;   
            }
            p {
                font-size: 12px;
                line-height: 1.7rem;
                
            }
            .description-paragraph {
                margin-bottom: .5rem;
            }
            .invite {
                margin-bottom: 2rem;
            }

            .arms-length {
                margin-bottom: .5rem;
            }
            .email {
                margin-bottom: 1.5rem;
            }

            .resume {
                text-decoration: underline;
                cursor: pointer;
                color: rgba(0,0,0,0.52);
            }
        }
    }
}